export function useSeo({
  title,
  description,
  titleOgImage,
}: {
  title: string
  description: string
  titleOgImage?: string
}) {
  useSeoMeta({
    title: title,
    ogTitle: title,
    description: description,
    ogDescription: description,
  })
  defineOgImageComponent('Nuxt', {
    headline: 'Juno.one',
    title: titleOgImage || title,
    description: description,
  })
}
